<template>
  <div class="w-100">
    <NavbarComponent />
    <swiper
      :direction="'vertical'"
      :slidesPerView="1"
      :spaceBetween="0"
      :mousewheel="true"
      :modules="modules"
      class="mySwiper"
    >
      <!-- <-----------headers :: start-------------------->

      <!-- <section id="about" class="about section-padding py-5" style="background-color: #e9eff4">
        <div class="container mt-md-5">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-12 col-12 mt-md-5">
                    <div class="about-text mx-auto">
                        <h3 class="text-center custom-text-color custom-font">
                            The world's largest collection of
                        </h3>
                        <h5 class="custom-font text-center display-5 custom-text-color custom-font fw-bold mt-3">
                            gemstones
                        </h5>

                        <div class="row mt-4">
                            <div class="col-lg-8 col-md-8 col-12">
                                <div class="about-text mx-auto">
                                    <input type="email" class="form-control rounded px-5" style="height: 50px" id="exampleFormControlInput1" placeholder="Your email address" />
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <button type="button" class="btn text-white fw-bold custom-font btn-block mt-3 mt-lg-0 mt-md-0 rounded w-100 h-100 py-2.5 custom-hover-color button-background-color">
                                    Explore Collection
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-md-5">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12 ps-lg-5 mt-5 container">
                    <div class="about-text mx-auto">
                        <img src="../assets/Heroimg.png" class="img-fluid" alt="Heroimg.png" />
                    </div>
                </div>
            </div>
        </div>
    </section> -->
      <swiper-slide class="">
        <section
          id="about"
          class="about section-padding py-4 custom-height d-flex align-items-center"
          style="background-color: #e9eff4"
        >
          <div class="row w-100 mx-auto gx-0 ">
            <div
              class="col-lg-6 col-md-12 col-12 ps-lg-5 mt-md-5 m-0 d-flex justify-content-center align-items-center"
            >
              <div class="about-text h-auto">
                <h3
                  class="custom-text-color fs-1 text-center text-lg-start"
                >
                  The world's largest collection of
                </h3>
                <h5
                  class="fs-1 custom-text-color text-center text-lg-start mt-lg-3"
                >
                  gemstones
                </h5>

                <div class="mt-4 text-lg-start text-center">
                  <button
                    type="button"
                    class="btn btn-primary custom-font px-2 px-lg-4 py-2 button-background-color"
                  >
                    Explore Collection
                    <i class="bi bi-arrow-right fw-bold custom-font"></i>
                  </button>
                </div>
              </div>
            </div>
          
            <!-- <div
              class="col-lg-6 col-md-12 col-11 border border-danger  d-lg-flex justify-content-end align-items-center rounded p-0"
            >
              <div class="about-img d-flex justify-content-center border border-info py-3">
                <img src="../assets/Hero-img.png" alt="" class="w-100" />
              </div>
            </div> -->
            <div
              class="col-lg-6 col-md-12 col-11 mx-auto   d-lg-flex justify-content-end align-items-center rounded p-0"
            >
              <div
                class="about-img d-flex justify-content-center justify-content-lg-end  py-3"
              >
                <img src="../assets/Hero-img.png" alt="" class="img-fluid w-75" />
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>
      <!-- <-----------headers :: end-------------------->
      <!-- <-----------------online section:: start----------->
      <swiper-slide class="">
        <section
          id="about"
          class="about section-padding py-5 custom-height d-flex align-items-center"
          style="background-color: #f0f8ff"
        >
          <div class="w-100">
            <div class="row justify-content-center gx-0">
              <div class="col-lg-6 col-md-6 col-12 ps-lg-5">
                <div class="about-text m-2 d-flex justify-content-center">
                  <img
                    src="../assets/Home/sec2/home_2.png"
                    class="img-fluid w-50"
                    alt="Heroimg.png"
                  />
                </div>
                <h3 class="text-center custom-text-color mt-3 p-2">
                  Finding the ideal diamond for a customer is today as simple as
                  shopping online.
                </h3>
              </div>
            </div>
            <div class="row gx-0">
              <div class="col-lg-6 col-7 col-md-11 mx-auto">
                <div class="container">
                  <div
                    class="row mt-0 justify-content-evenly align-items-top gx-0"
                  >
                    <div class="col-lg-2 col-md-2 col-12 mt-0">
                      <div
                        class="about-img d-flex justify-content-center align-items-top"
                        style="height: 40%"
                      >
                        <img
                          src="../assets/Home/sec2/delivery-truck-clock-1.svg.png"
                          alt=""
                          class="img-fluid rounded w-50"
                        />
                      </div>
                      <div class="">
                        <p
                          class="text-center custom-font custom-text-color fs-7"
                        >
                          Express delivery options within 1-2 days
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12 mt-0">
                      <div
                        class="about-img d-flex justify-content-center align-items-top"
                        style="height: 40%"
                      >
                        <img
                          src="../assets/Home/sec2/credit-card.svg.png"
                          alt=""
                          class="img-fluid rounded w-50"
                        />
                      </div>
                      <p class="text-center custom-font custom-text-color">
                        Available free 30-day credit terms
                      </p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12 mt-0">
                      <div
                        class="about-img d-flex justify-content-center align-items-center"
                        style="height: 40%"
                      >
                        <img
                          src="../assets/Home/sec2/shipment-previous.svg.png"
                          alt=""
                          class="img-fluid rounded w-50"
                        />
                      </div>
                      <p class="text-center custom-font custom-text-color">
                        Free returns for 35 days.
                      </p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12 mt-0">
                      <div
                        class="about-img d-flex justify-content-center align-items-center"
                        style="height: 40%"
                      >
                        <img
                          src="../assets/Home/sec2/shield-search.svg.png"
                          alt=""
                          class="img-fluid rounded w-50"
                        />
                      </div>
                      <p class="text-center custom-font custom-text-color">
                        Managed quality assurance and logistics
                      </p>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12 mt-0">
                      <div
                        class="about-img d-flex justify-content-center align-items-center"
                        style="height: 40%"
                      >
                        <img
                          src="../assets/Home/sec2/shipment-approve-smartphone.svg.png"
                          alt=""
                          class="img-fluid rounded w-50"
                        />
                      </div>
                      <p class="text-center custom-font custom-text-color">
                        Consolidated invoicing and shipping
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>
      <!-- <-----------------online section:: start----------->

      <!-- <------------diamonds section :: start------>
      <swiper-slide>
        <section
          id="about"
          class="about section-padding py-3 d-flex justify-content-center align-items-center custom-height"
          style="background-color: #e9eff4"
        >
          <div class="row gx-0">
            <div class="col-lg-7 col-10 col-md-8 mx-auto ">
              <div class="">
                <div class="row py-0 gx-0 mx-auto">
                  <div class="container px-lg-0">
                    <h1
                      class="text-center fs-1 custom-text-color px-lg-5 px-md-5"
                    >
                      The largest collection of natural & lab grown diamonds,
                      plus gemstones, melee & more
                    </h1>
                  </div>

                  <div class="col-lg-4 col-md-4 col-12 mt-5 ">
                    <div
                      class="about-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/Home/sec3/diamond.png.png"
                        alt=""
                        class="img-fluid rounded-pill w-50"
                      />
                    </div>
                    <div class="w-75 mx-auto">
                      <h5 class="text-center custom-text-color px-lg-2">
                        1.6 million natural & lab grown diamonds
                      </h5>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 mt-5">
                    <div
                      class="about-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/Home/sec3/gemstones-cut-out-purple.png.png"
                        alt=""
                        class="img-fluid rounded-pill w-50"
                      />
                    </div>
                    <div class="w-75 mx-auto">
                      <h5 class="text-center custom-text-color px-lg-2">
                        35,000 coloured gemstones
                      </h5>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-12 mt-5">
                    <div
                      class="about-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/Home/sec3/gemstones-cut-out-melee.png.png"
                        alt=""
                        class="img-fluid rounded-pill w-50"
                      />
                    </div>
                    <div class="w-75 mx-auto">
                      <h5 class="text-center custom-text-color px-lg-2">
                        Melee in all sizes, colors & clarities
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>

      <!-- <------------diamonds section :: end------>

      <!-- <--------------crowsel :: start -------------->
      <swiper-slide>
        <section
          id="about"
          class="about section-padding py-3 d-flex justify-content-center align-items-center button-background-color custom-height"
        >
          <div
            class="button-background-color"
            style="height: 400px; width: 90%"
          >
            <SwipperCard />
          </div>
          <!-- <div class="">
            <ScheduleCard />
        </div> -->
        </section>
      </swiper-slide>

      <!-- <--------------crowsel :: end -------------->
      <!-- <--------------------------Brilliance :: start -------------------->
      <swiper-slide>
        <section
          id="about"
          class="about section-padding py-5 custom-height d-flex justify-content-center align-items-center"
          style="background-color: #e9eff4"
        >
          <div class="container">
            <h1 class="text-center fs-1 custom-text-color">
              Perfection without a list
            </h1>

            <div class="row gx-0">
              <div class="col-12 col-lg-8 col-md-12 mx-auto">
                <h5
                  class="text-center fs-6 text-black-50 custom-font container mb-md-0 px-md-5"
                >
                  With the help of our diamond showcasing tools, shops may show
                  customers any stone from our collection in-person or online,
                  ensuring that you never lose another sale due to a lack of
                  stock.
                </h5>
              </div>
            </div>
            <div class="row gx-0">
              <div class="col-lg-10 mx-auto">
                <div class="row mt-2 gx-0 ">
                  <div
                    class="col-lg-6 col-md-12 col-12 d-flex justify-content-center  rounded mt-0 "
                  >
                    <div class="about-img d-flex justify-content-center  h-75">
                      <img
                        src="../assets/Home/sec-5-1.png"
                        alt=""
                        class="img-fluid "
                      />
                    </div>
                  </div>

                  <div
                    class="col-lg-6 col-md-12 col-12 ps-lg-5 mt-md-0 container"
                  >
                    <div class="about-text mx-auto">
                      <h4 class="fs-1 custom-text-color">
                        Virtual Showroom in-store
                      </h4>
                      <h5 class="text-black-50 fs-6 custom-font mt-1">
                        Showcase any stone in our collection to in-person
                        customers with high quality 360-degree videos and zero
                        inventory risk.
                      </h5>
                      <div class="d-flex gap-4 mt-2 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font m-0">
                            Always have what your consumers need in stock
                          </p>
                        </div>
                      </div>

                      <div class="d-flex gap-4 mt-0 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font m-0">
                            Strive to outbid your competition in order to close
                            every sale.
                          </p>
                        </div>
                      </div>
                      <div class="d-flex gap-4 mt-0 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font m-0">
                            Assure you're generating the highest possible
                            margins
                          </p>
                        </div>
                      </div>
                      <div class="d-flex gap-4 mt-0 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font m-0">
                            Instant quotes for consumers without memo
                          </p>
                        </div>
                      </div>

                      <button type="button" class="btn mt-1">
                        <span class="fw-bold custom-font custom-text-color"
                          >Learn More</span
                        >
                        <i
                          class="bi bi-arrow-right fw-bold custom-font custom-text-color"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>
      <swiper-slide>
        <section
          id="online"
          class="about section-padding py-lg-3 custom-height d-flex justify-content-center align-items-center"
          style="background-color: #e9eff4"
        >
          <div class="row gx-0">
            <div class="col-lg-10 mx-auto">
              <div class="container">
                <div class="row mt-lg-5 gx-0">
                  <div
                    class="col-lg-6 col-md-12 col-12 ps-lg-5 mt-md-0 container"
                  >
                    <div class="about-text mx-auto">
                      <h4 class="fs-1 custom-text-color">
                        Diamond Feeds Online
                      </h4>
                      <h5 class="text-black-50 fs-6 custom-font mt-2">
                        Utilize our API to display our assortment of diamonds
                        and gemstones on your website while maintaining control
                        over the size, quality, and other factors.
                      </h5>
                      <div class="d-flex gap-4 mt-3 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font">
                            Immediately increase the selection of diamonds and
                            gemstones available online.
                          </p>
                        </div>
                      </div>
                      <div class="d-flex gap-4 mt-0 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font mt-0">
                            Always have the appropriate stone on your website
                            for each visitor.
                          </p>
                        </div>
                      </div>
                      <div class="d-flex gap-4 mt-0 text-black-50 custom-font">
                        <i class="bi bi-check-lg fw-bold"></i>
                        <div>
                          <p class="text-black-50 custom-font">
                            Automated ordering will streamline processes.
                          </p>
                        </div>
                      </div>
                      <button type="button" class="btn mt-0 ">
                        <span class="fw-bold custom-font custom-text-color"
                          >Learn More</span
                        >
                        <i
                          class="bi bi-arrow-right fw-bold custom-font custom-text-color"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center rounded"
                  >
                    <div class="about-img d-flex justify-content-center">
                      <img
                        src="../assets/Home/Diamond_Feeds.png"
                        alt=""
                        class="img-fluid rounded-pill w-75"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>

      <!-- <div>
        <ScheduleCard />
    </div> -->
      <!-- video section starts -->
      <swiper-slide>
        <section
          id="about"
          class="about section-padding py-5 custom-height d-flex align-items-center"
          style="background-color: #e9eff4"
        >
          <div class="container h-75">
            <div class="container px-lg-5">
              <h2 class="text-center custom-text-color px-lg-3 px-md-5">
                Founded by a jeweler with a<br />
                vision for the sector
              </h2>
            </div>

            <div
              class="row justify-content-center align-items-center mt-lg-3 mt-3 gx-0"
            >
              <div class="col-lg-6 col-md-10 col-12 rounded">
                <div class="ratio ratio-16x9">
                  <iframe
                    src="https://www.youtube.com/embed/vlDzYIIOYmM"
                    title="YouTube video"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>
      <!-- video section end -->

      <!-- brand section starts -->
      <swiper-slide>
        <section
          id="about"
          class="about section-padding d-flex justify-content-center align-items-center py-5 custom-height button-background-color"
        >
          <div class="container">
            <h3 class="text-center fs-1  mt-5 text-white">
              Recognition on a global scale
            </h3>
            <div class="row gx-0">
              <div class="col-lg-8 col-10 mx-auto">
                <div
                  class="row mt-4 justify-content-evenly align-items-center gx-0"
                >
                  <div
                    class="col-md-2 col-lg-2 col-6 d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <div class="about-img py-5">
                        <img
                          src="../assets/UKJA.png.png"
                          alt="Recognition1"
                          class="img-fluid"
                        />
                      </div>
                      <div class="about-img py-5">
                        <img
                          src="../assets/jewellery-world.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-2 col-lg-2 col-6 d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <div class="about-text py-5">
                        <img
                          src="../assets/logo-copy.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="about-text py-5">
                        <img
                          src="../assets/18karani.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-lg-2 col-6 d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <div class="about-text py-5">
                        <img
                          src="../assets/retail-jeweller.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="about-text py-5">
                        <img
                          src="../assets/lofficiel.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-lg-2 col-6 d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <div class="about-text py-5">
                        <img
                          src="../assets/professional-jeweller-1.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="about-text py-5">
                        <img
                          src="../assets/zg.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-2 col-lg-2 col-6 d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <div class="about-text d-none py-5 d-md-block d-lg-block">
                        <img
                          src="../assets/national-jeweler.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="about-text py-5 d-none d-md-block d-lg-block">
                        <img
                          src="../assets/grupo.png.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>

      <!-- brand  section Ends -->

      <!-- <--------------------------Brilliance :: end -------------------->
      <!-- about section starts -->
      <swiper-slide>
        <section
          id="about"
          class="about section-padding py-5 custom-height"
          style="background-color: #e9eff4"
        >
          <h1 class="text-center fs-1 m-md-3 m-lg-2 custom-text-color ">
            Your brilliant partner
          </h1>
          <div class="row gx-0 ">
            <div class="col-lg-10 col-md-8 mx-auto">
              <div class="container">
                <div class="row gx-0">
                  <div class="col-lg-6 col-md-12 col-12 rounded">
                    <div
                      class="about-img d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="../assets/Home/sec8.png"
                        alt=""
                        class="img-fluid rounded-pill w-75"
                      />
                    </div>
                  </div>

                  <div
                    class="col-lg-6 col-md-12 col-12 ps-lg-5 mt-md-5 container d-flex justify-content-center align-items-center"
                  >
                    <div class="about-text mx-auto">
                      <h4 class="fs-1 custom-text-color">
                        Continual client<br />
                        service
                      </h4>
                      <p class="custom-font text-black-50">
                        You can rely on our dependable Customer Support Team to
                        respond to all technical and product questions as soon
                        as possible, no matter where you are or what time it is.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </swiper-slide>
      <!-- about section Ends -->
      <swiper-slide>
        <div class="">
          <IndestryCard />
        </div>
      </swiper-slide>
      <swiper-slide>
        <FooterComponent />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// @ is an alias to /src
import IndestryCard from "@/components/cards/IndestryCard.vue";
// import ScheduleCard from "@/components/cards/ScheduleCard.vue";
import SwipperCard from "@/components/SwipperCard.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import "swiper/css/pagination";

import { Mousewheel } from "swiper/modules";

export default {
  name: "HomeView",
  components: {
    IndestryCard,
    // ScheduleCard,
    SwipperCard,
    NavbarComponent,
    FooterComponent,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Mousewheel],
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 88.8vh;
}
.custom-text-color {
  color: #004987;
}

.button-background-color {
  background-color: #004987;
}

.custom-hover-color:hover {
  background-color: #0000d6 !important;
  color: #fff;
}

.custom-font {
  font-family: "Inter", sans-serif;
}

@media (min-width: 992px) {
  .custom-height {
    height: 100vh;
  }
}
</style>
