<template>
<div class="container  h-100 d-flex justify-content-center align-items-center">
    <swiper 
    :style="{
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
        '--swiper-navigation-size': '1.5rem',
        '--swiper-navigation-bg-color': '#ed64a6',
      }"
    :navigation="true" :autoplay="{
    delay: 2500,
    disableOnInteraction: false,
  }" :modules="modules" class="mySwiper   h-75 ">
        <swiper-slide>
            <div class="container  h-100 p-md-3 ">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>

        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
        <swiper-slide>
            <div class="container  h-100 p-md-3">
                <h1 class="dispaly-4 m-md-5 m-3 text-center text-white">“Nivoda has completely changed our diamond buying process, making it much simpler, faster, easier and cheaper too.”
                </h1>

                <p class="text-center fw-bold text-white"> — Emelie Tyler, Managing Director at Purely Diamonds</p>

            </div>
        </swiper-slide>
    </swiper>
</div>
</template>

<script>
// Import Swiper Vue.js components
import {
    Swiper,
    SwiperSlide
} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/navigation';

// import required modules
import {
    Navigation,
    Autoplay
} from 'swiper/modules';
export default {
    name: "SwipperCard",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {

        return {
            modules: [Autoplay, Navigation],
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
