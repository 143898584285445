<template>
  <!-- Footer -->
  <footer
    class="text-center text-lg-start text-muted py-0  d-flex align-items-center"
    style="
      background-color: #004987;
      background-image: url('../assets/footer-bg.png');
      height: 100vh;
    "
  >
    <!-- Section: Social media -->

    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <div class="row gx-0 w-100   ">
      <div class="col-12 mx-auto ">
        <div class="container text-center text-md-start w-100 mt-5">
          <!-- Grid row -->
          <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <!-- Content -->
              <div class="mb-3">
                <img
                  src="../assets/logo.png"
                  alt="companyLogo"
                  class="img-fluid"
                />
              </div>
              <div>
                <a href="" class="me-4 text-white">
                  <i class="bi bi-facebook"></i>
                </a>

                <a href="" class="me-4 text-white">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="" class="me-4 text-white">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
              <div class="mt-4">
                <!-- 
                        <div class=" mt-2">
                            <img src="../assets/footer-bg.png" alt="playStore_img" class="img-fluid  " />
                        </div> -->
              </div>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4 text-white d-none">
                Products
              </h6>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_career"
                  >Careers</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_contact"
                  >Contact</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_ethical"
                  >Ethical Practice</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_faq"
                  >FAQs</a
                >
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4 d-none">Useful links</h6>
              <p>
                <a type="button" class="text-white custom-font custom-text-size"
                  >Our Policies</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_AML"
                  >AML Policy</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_privacy"
                  >Privacy Policy</a
                >
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4 text-white d-none">
                Contact
              </h6>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size cursor-pointer"
                  @click="handle_term"
                  >Terms & Conditions</a
                >
              </p>
              <p>
                <a type="button" class="text-white custom-font custom-text-size"
                  >General Terms & Conditions</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_express"
                  >Express Supplier Terms & Conditions</a
                >
              </p>
              <p>
                <a
                  type="button"
                  class="text-white custom-font custom-text-size"
                  @click="handle_supply"
                  >Supply of Goods Terms & Conditions</a
                >
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
        <!-- Copyright -->
        <div class="text-center p-5 text-white-50">
          BlueMoon Limited is registered at Companies House under 10736332
        </div>
      </div>

      <!-- Copyright -->
    </div>
    <!-- Section: Links  -->
  </footer>
  <!-- Footer -->
</template>

<script>
import router from "@/router";
export default {
  name: "FooterComponent",
  setup() {
    const handle_ethical = () => {
      router.push("/ethical-practice");
    };
    const handle_faq = () => {
      router.push("/faqs");
    };
    const handle_career = () => {
      router.push("/careers");
    };
    const handle_contact = () => {
      router.push("/contact");
    };
    const handle_AML = () => {
      router.push("/aml-Policy");
    };
    const handle_privacy = () => {
      router.push("/privacy-policy");
    };
    const handle_term = () => {
      router.push("/term-condition");
    };
    const handle_express = () => {
      router.push("/supplier-condition");
    };
    const handle_supply = () => {
      router.push("/supply-goods-terms-condition");
    };
    return {
      handle_ethical,
      handle_faq,
      handle_career,
      handle_contact,
      handle_AML,
      handle_privacy,
      handle_term,
      handle_express,
      handle_supply,
    };
  },
};
</script>

<style scoped>
.custom-font {
  font-family: "Inter", sans-serif;
}

.custom-text-size {
  font-size: 18px;
}
</style>
