import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/retail',
    name: 'retail',
    component: () => import(/* webpackChunkName: "about" */ '../views/RetailsView.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResourcesView.vue')
  },
  {
    path: '/seller',
    name: 'sellers',
    component: () => import(/* webpackChunkName: "about" */ '../views/SellView.vue')
  },
  {
    path: '/ethical-practice',
    name: 'ethicalPractice',
    component: () => import(/* webpackChunkName: "about" */ '../views/EthicalPractice.vue')
  },
  {
    path: '/faqs',
    name: 'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/FaqsView.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "about" */ '../views/CareersView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/SetdemoView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  
  {
    path: '/aml-Policy',
    name: 'AmlPolicy',
    component: () => import( '../views/Policy/AmlPolicy.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import( '../views/Policy/PrivacyPolicy.vue')
  },
  {
    path: '/term-condition',
    name: 'term',
    component: () => import( '../views/Policy/TermCondition.vue')
  },
  // {
  //   path: '/term-condition',
  //   name: 'term',
  //   component: () => import( '../views/Policy/TermCondition.vue')
  // },
  {
    path: '/supplier-condition',
    name: 'express',
    component: () => import( '../views/Policy/SupplierPolicy.vue')
  },
  {
    path: '/supply-goods-terms-condition',
    name: 'supply',
    component: () => import( '../views/Policy/SupplyGoods.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( '../views/register/RegisterView1.vue'),
   
  },
  {
    path:'/check',
    name:'check',
    component:()=>import('../views/CheckView.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
