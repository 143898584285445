<template>
<div>
    <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
    
  <router-view />
 
    <!-- <RegisterView1 /> -->
</div>
</template>

<script>

// import RegisterView1 from "@/views/register/RegisterView1.vue"
export default {
    components: {
       
        // RegisterView1
    },
};
</script>

<style>
body {
    font-family: "Nanum Myeongjo", serif;
    /* font-family: 'Signika', sans-serif; */
    overflow-x: hidden;
    
}

a {
    text-decoration: none;
}
</style>
