<template>
<section id="portfolio" class="portfolio section-padding py-3 custom-height " style="background-color:#F0F8FF">
    <div class="container">
        <div class=" mt-4">
            <div class="col-md-12">
                <div class="section-header text-center pb-0">
                    <h1 class="custom-text-color fs-1">Business information and more</h1>
                 
                </div>
            </div>
        </div>
        <div class="row  mt-lg-2 ">
            <div class="col-12 col-md-6  col-lg-4">
                <div class="card text-light text-center bg-white pb-2 mt-2 ">
                    <div class="card-body text-dark m-0 p-0">
                        <div class="img-area mb-4">
                            <img src="../../assets/Home/img1.png" class="img-fluid rounded w-100 h-75 " alt="" />
                        </div>
                        <div class="m-3">
                            <h5 class="text-start text-black-50 fs-6 custom-font">CASE STUDIES</h5>
                            <h4 class=" text-start custom-font fs-5 custom-text-color">
                                Sarah Lee Jewelry by <br/>BlueMoon
                            </h4>
                            <p class="lead text-start custom-font text-black-50 fs-6">
                                Learn how the small company, Sarah Lee Jewelry, headquartered in the center.
                            </p>
                            <button class="btn btn-dark border-0 rounded  custom-font border-none px-4 py-2 w-100 custom-hover-color button-background-color" >
                                Read More...
                                <i class="bi bi-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6  col-lg-4 mt-3 mt-lg-0 mt-md-0">
                <div class="card text-light text-center bg-white mt-2 pb-2">
                    <div class="card-body text-dark m-0 p-0">
                        <div class="img-area mb-4">
                            <img src="../../assets/Home/Business_information.png" class="img-fluid w-100 " alt="" />
                        </div>
                        <div class="m-3">
                            <h5 class="text-start text-black-50 fs-6 custom-font">Blog Posts</h5>
                            <h4 class=" text-start custom-font fs-5 custom-text-color">
                                BM's first introduction to<br/> gemstones
                            </h4>
                            <p class="lead text-start custom-font text-black-50 fs-6">
                                We are delighted to inform that diamonds are now available on BM.! As BM grows.
                            </p>
                            <button class="btn btn-dark border-0 rounded  custom-font px-4 w-100 py-2  custom-hover-color button-background-color" >
                                Read More...
                                <i class="bi bi-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6   col-lg-4 mt-3 mt-lg-0 mt-md-0 rounded">
                <div class="card text-light text-center mt-2 bg-white pb-2">
                    <div class="card-body text-dark m-0 p-0">
                        <div class="img-area mb-4 m-0">
                            <img src="../../assets/Home/img3.png" class="img-fluid w-100" alt="" />
                        </div>
                        <div class="m-3">
                            <h5 class="text-start text-black-50 fs-6 custom-font">Case Studies</h5>
                            <h4 class="card-title text-start fs-5 custom-font custom-text-color">
                                BM x Melanie Casey case<br/> studies
                            </h4>
                            <p class="lead text-start custom-font text-black-50 fs-6">
                                Find out how BM helps Melanie Casey, an online seller of handcrafted fine jewelry.
                            </p>
                            <button class="btn btn-dark border-0  custom-font rounded px-4 py-2 w-100  custom-hover-color button-background-color" >
                                Read More...
                                <i class="bi bi-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="d-flex justify-content-center mt-3">
            <button class="btn btn-light rounded px-5 py-3 custom-text-color custom-font fw-bold custom-hover-color" >
                View each resource
                <i class="bi bi-arrow-right"></i>
            </button>

        </div> -->
      
    </div>
</section>
</template>

<script>
export default {
    name: "IndestryCard",
    setup() {
        return {};
    },
};
</script>

<style  scoped>
.custom-text-color {
    color: #004987;
}
.custom-hover-color:hover {
  background-color: #0000D6 !important;
  color: #fff;
}
.button-background-color{
    background-color: #004987;
}

.custom-font {
    font-family: 'Inter', sans-serif;
}
@media (min-width: 992px) {
    .custom-height {
        height: 90vh;
    }
    .custom-card-height{
        height: 60vh;

    }
}
</style>
