<template>
<nav class="navbar navbar-expand-lg sticky-top " style="height: 90px; background-color:#004987">
    <div class="container-fluid custom-padding" style="background-color: #004987;">
        <a class="navbar-brand text-white custom-font fw-bold " href="#" @click="handle_home">
            <img src="../assets/logo.png" alt="logo" class="img-fluid w-75" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <!-- <span class="navbar-toggler-icon text-white"></span> -->
            <i class="bi bi-list text-white fs-1"></i>
        </button>
        <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0  ms-3">
                <li class="nav-item">
                    <a type="button" class="nav-link text-white px-0 py-3 custom-margin custom-font fw-bold fs-6" aria-current="page" @click="handle_retail">Retail Tools</a>
                </li>
                <li class="nav-item">
                    <a type="button" class="nav-link text-white custom-font fw-bold  px-0 py-3 fs-6 custom-margin custom-margin-left cursor-pointer" @click="handle_about">About</a>
                </li>
                <li class="nav-item">
                    <a type="button" class="nav-link text-white custom-font fw-bold fs-6 px-0 py-3 custom-margin custom-margin-left cursor-pointer" @click="handle_resources">Resources</a>
                </li>
                <li class="nav-item">
                    <a type="button" class="nav-link text-white custom-font fw-bold fs-6 px-0 py-3 custom-margin custom-margin-left cursor-pointer" @click="handle_seller">Sell With Us</a>
                </li>
                <li class="nav-item">
                    <a type="button" class="nav-link text-white custom-font fw-bold fs-6 px-0 py-3  custom-margin-left cursor-pointer"  @click="handle_contact">Contact</a>
                </li>
            </ul>
            <form class="d-flex gap-4">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link text-white custom-font fw-bold fs-6 px-0 py-3 custom-margin" href="#" @click="handle_demo">Schedule Demo</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link fw-bold text-white custom-font fs-6 px-0 py-3 custom-margin custom-margin-left" href="#" @click="handle_login">Log In</a>
                    </li>

                    <button type="button" class="btn btn-sm shadow-lg bg-white rounded ms-2 py-1 px-3 m-2 custom-font text-color fs-6 fw-bold  custom-hover-color" >
                        Join for free
                    </button>
                </ul>
            </form>
        </div>
    </div>
</nav>
</template>

<script>
import router from '@/router';
export default {
    name: "NavbarComponent",
    setup() {
        const handle_home = () => {
            router.push('/');

        }

        const handle_retail = () => {
            router.push('/retail');

        }
        const handle_about = () => {
            router.push('/about');

        }
        const handle_resources = () => {
            router.push('/resources');

        }
        const handle_seller = () => {
            router.push('/seller');

        }
        const handle_contact = () => {
            router.push('/contact');
        }
        const handle_demo = () => {
            router.push('/demo');

        }
        const handle_login = () => {
            router.push('/login');

        }
        const join_free = () => {
            router.push('/register');

        }
        return {
            handle_retail,
            handle_about,
            handle_resources,
            handle_seller,
            handle_demo,
            handle_home,
            handle_contact,
            handle_login,
            join_free
        };
    },
};
</script>

<style scoped>
.custom-font {
    font-family: 'Inter', sans-serif;
}

.custom-padding {
    padding: 20px 36px;
}

.custom-hover-color:hover {
    background-color: #0000D6 !important;
    color: #fff;
}

.text-color {
    color:#004987;
}

.custom-text-size {
    font-size: 18px;
}

.custom-padding-top {
    padding: 13px 0;
}

.custom-margin {
    margin-right: 10px;
}

.custom-margin-left {
    margin-left: 10px;
}
</style>
